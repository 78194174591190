<template>
  <el-form :model="withdrawForm" :rules="rulesWithdraw" ref="withdrawForm">
    <div class="top">
      <SelectForm
        v-model="withdrawForm.qAccount"
        :label="$t('common.keys.rebateAccount')"
        name="qAccount"
        class="showPlaceholder"
      >
        <el-option
          v-for="item in accountList"
          :key="item.login"
          :label="item.login + ' - ' + item.balance + ' ' + item.currency"
          :value="item.login"
          :data-testid="item.login"
        ></el-option>
      </SelectForm>
      <SelectForm
        v-model="withdrawForm.withdrawalType"
        :label="$t('withdraw.PleaseSelectDesiredWithdrawMethod')"
        name="withdrawalType"
        class="showPlaceholder"
      >
        <el-option
          v-for="item in withdrawalSupportedTypes"
          :key="item.id"
          :label="$t(item.nameKey)"
          :value="item.id"
          :data-testid="'withdrawalType' + item.id"
        ></el-option>
      </SelectForm>

      <numeric-input
        v-model="withdrawForm.amount"
        :precision="2"
        :currency="withdrawForm.currency"
        :label="$t('common.keys.amount')"
        name="amount"
        ref="numberInput"
      ></numeric-input>
    </div>
    <div class="alert" v-if="withdrawForm.withdrawalType === 4">
      <p>
        {{ $t('withdraw.alert.title') }}
      </p>
      <ul>
        <li>{{ $t('withdraw.alert.desc1', { units: AMLAlert.units, charge: AMLAlert.charge }) }}</li>
      </ul>
    </div>
  </el-form>
</template>

<script>
import { integerOrMaxTwoDecimalNumbersCreator } from '@/util/validators';
import { apiGetWithdrawalData } from '@/resource';
import NumericInput from '@/components/NumericInput';

export default {
  name: 'top',
  components: { NumericInput },
  props: {
    chooseWithdrawalType: Object,
    AMLAlert: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data() {
    return {
      accountList: [],
      withdrawalTypes: [],
      withdrawalSupportedTypes: [],
      countries: [],
      countryBankList: null,
      countryCode: null,
      availableBalance: '',
      withdrawForm: {
        qAccount: '',
        amount: '',
        currency: '',
        withdrawalType: null
      }
    };
  },
  computed: {
    minLimit() {
      return this.chooseWithdrawalType.amountLimit;
    },
    rulesWithdraw() {
      return {
        qAccount: [
          {
            required: true,
            message: this.$t('common.keys.pleaseSelect'),
            trigger: 'change'
          }
        ],
        withdrawalType: [
          {
            required: true,
            message: this.$t('common.keys.pleaseSelect'),
            trigger: 'blur'
          }
        ],
        amount: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.amt'),
            trigger: 'blur'
          },
          {
            validator: integerOrMaxTwoDecimalNumbersCreator(this.$t('withdraw.CannotUseMoreThanTwoDecimalValues')),
            trigger: 'blur'
          },
          {
            validator: (rule, value, callback) => {
              if (value < this.minLimit) {
                callback(
                  new Error(
                    this.$t('withdraw.amtLarger', {
                      minLimit: this.minLimit
                    })
                  )
                );
              } else if (value > this.availableBalance) {
                callback(new Error(this.$t('withdraw.YourPaymentGreaterThanAvailableBalance')));
              } else callback();
            },
            trigger: 'blur'
          }
        ]
      };
    }
  },
  watch: {
    withdrawForm: {
      handler(val, oldVal) {
        this.$emit('withdrawFormChange', val);
      },
      deep: true
    },
    'withdrawForm.qAccount'(val) {
      this.withdrawForm.withdrawalType = '';
      if (val === '') {
        return;
      }
      this.getWithdrawalData();
      const [currAccountInfo] = this.accountList.filter(item => {
        return item.login === val;
      });
      if (currAccountInfo) {
        this.availableBalance = currAccountInfo.balance;
        this.withdrawForm.currency = currAccountInfo.currency;
      }
      if (this.withdrawForm.currency != 'USD') {
        if (['HKD'].includes(this.withdrawForm.currency) && ['7033', '7069'].includes(this.countryCode)) {
          this.withdrawalSupportedTypes = this.withdrawalTypes.filter(item => item.id != 4);
        } else {
          this.withdrawalSupportedTypes = this.withdrawalTypes.filter(item => item.id != 4 && item.id != 49);
        }
      } else if (['USD'].includes(this.withdrawForm.currency) && ['7033', '7069'].includes(this.countryCode)) {
        this.withdrawalSupportedTypes = this.withdrawalTypes;
      } else {
        this.withdrawalSupportedTypes = this.withdrawalTypes.filter(item => item.id != 49);
      }

      this.$nextTick(() => {
        if (this.withdrawForm.amount) {
          this.$refs.withdrawForm.validateField('amount', valid => {
            if (!valid) return false;
          });
        }
        if (this.withdrawForm.withdrawalType) {
          this.$refs.withdrawForm.validateField('withdrawalType');
        }
      });
    }
  },
  mounted() {
    this.getWithdrawalData();
  },
  methods: {
    getWithdrawalData() {
      let body = { qUserId: this.$store.state.common.CUID };
      if (this.withdrawForm.qAccount) Object.assign(body, { accountNumber: this.withdrawForm.qAccount.toString() });
      apiGetWithdrawalData(body).then(resp => {
        if (resp.data.code === 0) {
          this.countryCode = resp.data.data.countryCode;
          this.accountList = resp.data.data.logins.filter(item => item.accountDealType === 3);
          this.withdrawalSupportedTypes = this.withdrawalTypes = resp.data.data.withdrawalType;
          this.countryBankList = resp.data.data.withdrawBankList;
          this.$emit('setCountryCode', this.countryCode);
          this.$emit('setBankList', this.countryBankList);
          if (this.withdrawForm.qAccount === '' && this.accountList && this.accountList.length > 0) {
            this.$nextTick(() => {
              // 默认选中首页选中的账号
              this.$set(this.withdrawForm, 'qAccount', this.accountList[0].login);
            });
          }
        }
      });
    },
    submitForm() {
      return this.$refs.withdrawForm.validate();
    }
  }
};
</script>
