<template>
  <el-form
    :model="form"
    :rules="rulesNeteller"
    ref="withdrawFormNeteller"
    class="form_bottom_row clearfix"
    id="netellerForm"
  >
    <div class="fr img_row">
      <img src="@/assets/uploads/withdraw/withdraw_neteller_logo.png" alt />
    </div>
    <div class="fl form_row">
      <ul class="form_list">
        <li>
          <InputForm
            :label="$t('withdraw.NetellerEmail')"
            name="skrillEmail"
            maxlength="100"
            type="text"
            v-model="form.skrillEmail"
          ></InputForm>
        </li>
        <li>
          <ImportantNotesInput
            :label="$t('withdraw.ImportantNotes')"
            name="notes"
            maxlength="100"
            type="text"
            v-model="form.importantNotes"
          ></ImportantNotesInput>
        </li>
      </ul>
      <div class="alert_info">
        {{ $t('withdraw.NetellerChargeAlert') }}
      </div>
    </div>
  </el-form>
</template>

<script>
import { validateEmail } from '@/util/validators';
import ImportantNotesInput from '@/components/form/ImportantNotesInput';

export default {
  components: { ImportantNotesInput },
  data() {
    return {
      form: {
        skrillEmail: '',
        importantNotes: ''
      },
      rulesNeteller: {
        skrillEmail: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.email'),
            trigger: 'blur'
          },
          {
            validator: validateEmail(this.$t('withdraw.formErrorMsg.emailFormat')),
            trigger: 'blur'
          }
        ]
      }
    };
  },
  watch: {
    form: {
      handler(val, oldVal) {
        this.$emit('formChange', val);
      },
      deep: true
    }
  },
  methods: {
    submitForm() {
      return this.$refs.withdrawFormNeteller.validate();
    }
  }
};
</script>
