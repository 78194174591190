<template>
  <el-form
    :model="form"
    :rules="rulesPerfectMoney"
    ref="withdrawFormPerfectMoney"
    class="form_bottom_row clearfix"
    id="perfectMoneyForm"
  >
    <div class="fr img_row">
      <img class="small" src="@/assets/uploads/withdraw/withdraw_PerfectMoney.png" alt />
    </div>
    <div class="fl form_row">
      <ul class="form_list">
        <li>
          <InputForm
            :label="$t('withdraw.perfectMoneyEmail')"
            name="perfectMoneyEmail"
            maxlength="100"
            type="text"
            v-model="form.perfectMoneyEmail"
          ></InputForm>
        </li>
        <li>
          <ImportantNotesInput
            :label="$t('withdraw.ImportantNotes')"
            name="notes"
            maxlength="100"
            type="text"
            v-model="form.importantNotes"
          ></ImportantNotesInput>
        </li>
      </ul>
    </div>
  </el-form>
</template>

<script>
import { validateEmail } from '@/util/validators';
import ImportantNotesInput from '@/components/form/ImportantNotesInput';

export default {
  components: { ImportantNotesInput },
  data() {
    return {
      form: {
        perfectMoneyEmail: '',
        importantNotes: ''
      },
      rulesPerfectMoney: {
        perfectMoneyEmail: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.dynamicReq', { fieldname: this.$t('withdraw.perfectMoneyEmail') }),
            trigger: 'blur'
          },
          {
            validator: validateEmail(this.$t('withdraw.formErrorMsg.emailFormat')),
            trigger: 'blur'
          }
        ]
      }
    };
  },
  watch: {
    form: {
      handler(val, oldVal) {
        this.$emit('formChange', val);
      },
      deep: true
    }
  },
  methods: {
    submitForm() {
      return this.$refs.withdrawFormPerfectMoney.validate();
    }
  }
};
</script>
