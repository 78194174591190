<template>
  <div id="rebateWithdraw">
    <div class="wrapper_main">
      <div class="wrapper_content">
        <div class="content_title">
          <h2>{{ $t('withdraw.REBATE_WITHDRAW_REQUEST') }}</h2>

          <p></p>
        </div>

        <div class="form_container">
          <div class="box box_card_top">
            <Top
              ref="top"
              :chooseWithdrawalType="chooseWithdrawalType"
              :AMLAlert="AMLAlert"
              @withdrawFormChange="withdrawFormChange"
              @setCountryCode="getCountryCode"
              @setBankList="getBankList"
            ></Top>
          </div>

          <div class="box box_card_bottom" v-if="transferFlag || currentComponent">
            <component
              :is="currentComponent"
              :dialog.sync="dialog"
              :userCountryCode="userCountryCode"
              :bankList="userBankList"
              :withdrawalType="withdrawForm.withdrawalType"
              @formChange="formChange"
              :chooseWithdrawalType="chooseWithdrawalType"
              ref="child"
            ></component>

            <div class="form_button" v-if="currentComponent">
              <el-button
                class="purple_button"
                :loading="loading"
                :disabled="loading"
                @click="submitForm('withdrawForm')"
              >
                {{ $t('common.keys.SUBMIT') }}
              </el-button>
            </div>
          </div>
        </div>

        <vDialog :show.sync="dialog.show">
          <p>{{ dialog.text }}</p>

          <div class="form_button" v-if="dialog.method === 'noCard'">
            <el-button class="purple_button" @click="confirm"> {{ $t('common.button.confirm') }} </el-button>
          </div>

          <div class="form_button" v-if="dialog.method === 'amountFee'">
            <el-button class="purple_button" @click="confirmAmountFee"> {{ $t('common.button.confirm') }} </el-button>

            <el-button class="white_button" @click="dialog.show = false"> {{ $t('common.button.cancel') }} </el-button>
          </div>
        </vDialog>
      </div>
    </div>
  </div>
</template>

<script>
import Top from '@/components/rebateWithdraw/Top';
import UnionPayCps from '@/components/rebateWithdraw/UnionPayCps';
import Fasapay from '@/components/rebateWithdraw/Fasapay';
import BankTransfer from '@/components/rebateWithdraw/BankTransfer';
import International from '@/components/rebateWithdraw/International';
import Neteller from '@/components/rebateWithdraw/Neteller';
import Skrill from '@/components/rebateWithdraw/Skrill';
import Vietnam from '@/components/rebateWithdraw/Vietnam';
import Bitcoin from '@/components/rebateWithdraw/Bitcoin';
import USDT from '@/components/rebateWithdraw/USDT';
import Thailand from '@/components/rebateWithdraw/Thailand';
import Malaysia from '@/components/rebateWithdraw/Malaysia';
import HongKong from '@/components/rebateWithdraw/HongKong';
import Indonesia from '@/components/rebateWithdraw/Indonesia';
import Laos from '@/components/rebateWithdraw/Laos';
import India from '@/components/rebateWithdraw/India';
import Dasshpe from '@/components/rebateWithdraw/Dasshpe';
import Philipines from '@/components/rebateWithdraw/Philipines';
import PerfectMoney from '@/components/rebateWithdraw/PerfectMoney';
import MexicoAndBrazil from '@/components/rebateWithdraw/MexicoAndBrazil';
import vDialog from '@/components/vDialog';
import { apiApplyWithdrawal } from '@/resource';
import mixin from '@/mixins';
import signHelper from '@/util/signinHelper';

export default {
  components: {
    Top,
    UnionPayCps,
    Fasapay,
    International,
    Neteller,
    Skrill,
    Vietnam,
    Bitcoin,
    USDT,
    Thailand,
    Philipines,
    vDialog
  },
  mixins: [mixin],
  data() {
    return {
      topKey: Math.random(),
      userId: this.$store.state.common.CUID,
      accountName: this.$store.state.common.userName,
      country: null,
      form: {},
      withdrawForm: {},
      chooseWithdrawalType: {},
      dialog: {
        show: false,
        text: '',
        method: ''
      },
      userCountryCode: null,
      userBankList: null,
      currentComponent: null,
      transferFlag: false,
      prevRoute: null,
      AMLAlert: { units: 100, charge: 20 },
      loading: false
    };
  },
  computed: {
    checkCountry() {
      return [4850, 6163, 5015, 6877].includes(parseInt(this.userCountryCode));
    }
  },
  watch: {
    country(value) {
      if (parseInt(value) == 2) {
        this.currentComponent = International;
      }
    },
    'withdrawForm.withdrawalType'(value) {
      this.country = null;

      this.chooseWithdrawalType = this.$refs.top.withdrawalTypes.find(f => f.id == value);
      this.transferFlag =
        this.chooseWithdrawalType && this.chooseWithdrawalType.withdrawSubTypes.length > 0 ? true : false;

      if (value == 2) {
        this.currentComponent = BankTransfer;
      } else if (value == 4) {
        this.currentComponent = UnionPayCps;
      } else if (value == 49) {
        this.currentComponent = HongKong;
      } else if (value == 5) {
        this.currentComponent = Thailand;
      } else if (value == 6) {
        this.currentComponent = Malaysia;
      } else if (value == 7) {
        this.currentComponent = Fasapay;
      } else if (value == 8) {
        this.currentComponent = Vietnam;
      } else if (value == 24) {
        this.currentComponent = India;
      } else if (value == 31) {
        this.currentComponent = Skrill;
      } else if (value == 32) {
        this.currentComponent = Neteller;
      } else if (value == 34) {
        this.currentComponent = Bitcoin;
      } else if (value == 36) {
        this.currentComponent = USDT;
      } else if (value == 39) {
        this.currentComponent = Indonesia;
      } else if (value == 40) {
        this.currentComponent = Philipines;
      } else if (value == 47) {
        this.currentComponent = Laos;
      } else if (value == 48) {
        this.currentComponent = Dasshpe;
      } else if (value == 61) {
        this.currentComponent = PerfectMoney;
      } else if (value == 63) {
        this.currentComponent = MexicoAndBrazil;
      } else if (value == 64) {
        this.currentComponent = MexicoAndBrazil;
      } else {
        this.currentComponent = null;
      }
    },
    userCountryCode() {
      if (this.checkCountry) {
        this.AMLAlert = { units: 50, charge: 15 };
      }
    }
  },
  //get previous path
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from;
    });
  },
  methods: {
    getCountryCode(code) {
      this.userCountryCode = code;
    },
    getBankList(bankList) {
      this.userBankList = bankList;
    },
    withdrawFormChange(form) {
      this.withdrawForm = form;
    },
    formChange(form) {
      this.form = form;
    },
    getFormDataOfChild() {
      return this.$refs.child.getFormData && this.$refs.child.getFormData();
    },
    submitForm() {
      this.loading = true;
      const top = this.$refs.top.submitForm(),
        comp = this.$refs.child.submitForm(),
        vm = this;
      Promise.all([top, comp]).then(function (values) {
        const amount = vm.withdrawForm.amount;
        if (amount < vm.AMLAlert.units && amount !== 0 && vm.withdrawForm.withdrawalType === 4) {
          this.loading = false;
          vm.dialog = {
            show: true,
            text: vm.$t('withdraw.withdrawlFeeAlert', {
              units: vm.AMLAlert['units'],
              charge: vm.AMLAlert['charge']
            }),
            method: 'amountFee'
          };
        } else {
          vm.applyWithdraw();
        }
      });
    },
    confirm() {
      if (this.dialog.method == 'noCard') this.goToClientPortalDrawDetail();
    },
    goToClientPortalDrawDetail() {
      signHelper.signOut('backToClientPortal', '/paymentDetails');
    },
    confirmAmountFee() {
      this.dialog.show = false;
      this.applyWithdraw();
    },
    // 出金请求
    async applyWithdraw() {
      let customFormData = this.getFormDataOfChild();
      let form = customFormData || { ...this.form };
      const getVerificationCode = this.$refs.child.getVerificationCode;
      let getVerifyCode = getVerificationCode ? this.$refs.child.getVerificationCode() : '';

      apiApplyWithdrawal(
        {
          ...this.withdrawForm,
          ...form,
          ...getVerifyCode,
          qUserId: this.userId,
          accountName: this.accountName
        },
        this.token
      ).then(resp => {
        if (resp.data.code === 0) {
          this.loading = false;
          this.$message.success({ duration: 5000, message: this.$t('withdraw.YourPaymentIsBeingProcessed') });
          this.bus.$emit('updateWithdrawHistory', true);
          this.$nextTick(function () {
            this.$router.push(this.prevRoute && this.prevRoute.path != '/' ? this.prevRoute.name : 'home');
          });
        } else if (resp.data.code === 551) {
          this.loading = false;
          // Balance insufficient -> refresh min amount
          this.topKey = Math.random();
        } else {
          this.$message({ message: this.$t('withdraw.default.failed'), type: 'error' });
          this.fetchToken()
            .then(resp => {
              this.loading = false;
            })
            .catch(resp => {
              this.loading = false;
              this.$message({ message: this.$t('withdraw.default.tokenExpired'), type: 'error' });
            });
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
@import '@/assets/css/pages/rebateWithdraw.scss';
</style>
