<template>
  <div>
    <el-form :model="form" :rules="rules" ref="withdrawFormChild" class="form_bottom_row clearfix">
      <div class="fr img_row">
        <img class="small" src="@/assets/uploads/withdraw/usdt.png" alt />
      </div>
      <div class="fl form_row">
        <ul class="form_list">
          <li>
            <SelectForm v-model="form.chain" :label="$t('withdraw.Chain')" name="chain">
              <el-option
                v-for="option in formChainOptions"
                :key="option.value"
                :label="option.text"
                :value="option.value"
              ></el-option>
            </SelectForm>
          </li>
          <li>
            <InputForm
              :label="$t('withdraw.USDTEmail')"
              name="cryptoWalletAddress"
              maxlength="100"
              type="text"
              v-model="form.cryptoWalletAddress"
            ></InputForm>
          </li>
          <!-- crypto的出金增加验证码 -->
          <li>
            <VerificationCode ref="verificationCode" namespace="IB_WITHDRAW"></VerificationCode>
          </li>
          <li>
            <ImportantNotesInput
              :label="$t('withdraw.ImportantNotes')"
              name="notes"
              maxlength="100"
              type="text"
              v-model="form.importantNotes"
            ></ImportantNotesInput>
          </li>
        </ul>
        <div class="alert_info">
          {{ $t('withdraw.cryptoAlert') }}
        </div>
        <div v-if="showUSDTNote" class="alert_info">
          {{ $t('withdraw.usdtNote') }}
        </div>
      </div>
    </el-form>
    <disclaimer-dialog></disclaimer-dialog>
  </div>
</template>

<script>
import disclaimerDialog from '@/components/disclaimerDialog';
import VerificationCode from '@/components/rebateWithdraw/VerificationCode';
import ImportantNotesInput from '@/components/form/ImportantNotesInput';

export default {
  components: { disclaimerDialog, VerificationCode, ImportantNotesInput },

  data() {
    return {
      formChainOptions: [
        { text: 'TRC20', value: 'trc20' },
        { text: 'ERC20', value: 'erc20' }
      ],
      form: {
        cryptoWalletAddress: '',
        importantNotes: '',
        chain: 'trc20'
      },
      rules: {
        cryptoWalletAddress: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.usdt'),
            trigger: 'blur'
          },
          {
            validator: (rule, value, callback) => {
              const chainType = this.form.chain;

              if (chainType === 'trc20' && !/^T/.test(value)) {
                callback(new Error(this.$t('withdraw.formErrorMsg.walletFormatIncorrect', { type: 'TRC20' })));
              } else if (chainType === 'erc20' && !/^0x/.test(value)) {
                callback(new Error(this.$t('withdraw.formErrorMsg.walletFormatIncorrect', { type: 'ERC20' })));
              } else {
                callback();
              }
            },
            trigger: ['blur', 'change']
          }
        ],
        chain: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.chain'),
            trigger: 'change'
          },
          {
            validator: (rule, value, callback) => {
              this.$refs.withdrawFormChild.validateField('cryptoWalletAddress');
              callback();
            },
            trigger: 'change'
          }
        ]
      }
    };
  },
  watch: {
    form: {
      handler(val, oldVal) {
        this.$emit('formChange', val);
      },
      deep: true
    }
  },
  computed: {
    showUSDTNote() {
      return this.form.chain === 'erc20';
    }
  },
  methods: {
    submitForm() {
      return this.$refs.withdrawFormChild.validate() && this.$refs.verificationCode.submitForm();
    },
    getVerificationCode() {
      return this.$refs.verificationCode.getFormDataOfChild();
    }
  }
};
</script>
