<template>
  <el-form
    :model="form"
    :rules="rulesFasapay"
    ref="withdrawFormFasapay"
    class="form_bottom_row clearfix"
    id="fasapayForm"
  >
    <div class="fr img_row">
      <img src="@/assets/uploads/withdraw/fasapay.png" alt />
    </div>
    <div class="fl form_row">
      <ul class="form_list">
        <li>
          <InputForm
            :label="$t('withdraw.FasaPayAccountName')"
            name="bankName"
            maxlength="100"
            type="text"
            v-model="form.bankName"
          ></InputForm>
        </li>
        <li>
          <InputForm
            :label="$t('withdraw.FasaPayAccountNumber')"
            name="accountNumber"
            maxlength="100"
            type="text"
            v-model="form.accountNumber"
          ></InputForm>
        </li>
      </ul>
      <ul class="form_list">
        <li>
          <ImportantNotesInput
            :label="$t('withdraw.ImportantNotes')"
            name="notes"
            maxlength="100"
            type="text"
            v-model="form.importantNotes"
          ></ImportantNotesInput>
        </li>
      </ul>
      <div class="alert_info">
        {{ $t('withdraw.FasaPayChargeAlert') }}
      </div>
    </div>
  </el-form>
</template>

<script>
import ImportantNotesInput from '@/components/form/ImportantNotesInput';

export default {
  components: { ImportantNotesInput },
  data() {
    return {
      form: {
        bankName: '',
        accountNumber: '',
        importantNotes: ''
      },
      rulesFasapay: {
        bankName: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.bankName'),
            trigger: 'blur'
          }
        ],
        accountNumber: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.accNum'),
            trigger: 'blur'
          }
        ]
      }
    };
  },
  watch: {
    form: {
      handler(val, oldVal) {
        this.$emit('formChange', val);
      },
      deep: true
    }
  },
  methods: {
    submitForm() {
      return this.$refs.withdrawFormFasapay.validate();
    }
  }
};
</script>
