<template>
  <el-form :model="form" :rules="TransferFormRules" ref="withdrawFormMB" class="form_bottom_row clearfix">
    <div class="fl form_row">
      <ul class="form_list clearfix">
        <li class="fl">
          <SelectForm v-model="form.selectedCardID" :label="$t('withdraw.default.selectBA')" name="selectedCardID">
            <el-option
              v-for="item in availableCards"
              :key="item.id"
              :value="item.id"
              :label="item.id | cardInfoDropdown(item, withdrawalType, dropdownTranslationObj)"
              :data-testid="`select_${item.id}`"
            ></el-option>
          </SelectForm>
        </li>
      </ul>
    </div>
    <div class="fl form_row" v-if="form.selectedCardID || form.selectedCardID === 0">
      <ul class="form_list">
        <li>
          <SelectForm v-model="form.bankName" name="bankName" :label="$t('withdraw.BankName')" :disabled="isdisabled">
            <el-option
              v-for="(value, name) in bankList"
              :key="name"
              :value="value"
              :label="name"
              :data-testid="name"
            ></el-option>
          </SelectForm>
        </li>
        <li>
          <InputForm
            :label="$t('withdraw.BankBeneficiaryName')"
            name="beneficiaryName"
            maxlength="100"
            v-model="form.beneficiaryName"
            :disabled="isdisabled"
          ></InputForm>
        </li>
      </ul>
      <ul class="form_list">
        <li>
          <InputForm
            :label="$t('withdraw.BankAccountNumber')"
            name="accountNumber"
            maxlength="100"
            v-model.trim="form.accountNumber"
            :disabled="isdisabled"
          ></InputForm>
        </li>
        <li>
          <InputForm
            :label="$t('withdraw.Province')"
            name="bankProvince"
            maxlength="100"
            v-model="form.bankProvince"
            :disabled="isdisabled"
          ></InputForm>
        </li>
      </ul>
      <ul class="form_list">
        <li>
          <InputForm
            :label="$t('withdraw.City')"
            name="bankCity"
            maxlength="100"
            v-model="form.bankCity"
            :disabled="isdisabled"
          ></InputForm>
        </li>
        <li>
          <ImportantNotesInput
            :label="$t('withdraw.ImportantNotes')"
            maxlength="100"
            v-model="form.importantNotes"
            name="notes"
          ></ImportantNotesInput>
        </li>
      </ul>
      <ul class="form_list" v-if="!isdisabled">
        <li>
          <el-form-item class="upload" prop="uploadedFile">
            <div class="labelTitle">
              <span class="required_icon">*</span>
              <label for="">{{ $t('withdraw.transfer.label.upload') }}</label>
            </div>
            <vUpload :limit="1" v-on:updateFileInfo="updateFileInfo" data-testid="updateFile"></vUpload>
          </el-form-item>
        </li>
      </ul>

      <div class="checkbox_wrapper" v-if="!form.userPaymentInfoId">
        <el-checkbox v-model="form.isRememberInfo" data-testid="rememberInfo">
          {{ $t('withdraw.default.remember') }}</el-checkbox
        >
      </div>
    </div>
  </el-form>
</template>

<script>
import withdrawlMixin from '@/mixins/withdrawl/withdrawl';
import bankTransferMixin from '@/mixins/withdrawl/bankTransfer';
import vUpload from '@/components/vUpload';
import ImportantNotesInput from '@/components/form/ImportantNotesInput';

export default {
  props: {
    withdrawalType: Number,
    bankList: Object
  },
  mixins: [bankTransferMixin, withdrawlMixin],
  components: { vUpload, ImportantNotesInput },
  data() {
    const validateFile = (rule, value, callback) => {
      if (this.fileList.length < 1) callback(new Error(this.$t('withdraw.transfer.formValidation.upload')));
      else callback();
    };
    return {
      form: {
        bankName: '',
        accountNumber: '',
        beneficiaryName: '',
        bankProvince: '',
        bankCity: '',
        importantNotes: '',
        userPaymentInfoId: '',
        selectedCardID: null
      },
      fileList: [],
      isRememberInfo: true,
      TransferFormRules: {
        bankName: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.bankName'),
            trigger: 'blur'
          }
        ],
        accountNumber: [
          {
            required: true,
            validator: this.validateAccountNumber,
            trigger: 'blur'
          }
        ],
        beneficiaryName: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.beneName'),
            trigger: 'blur'
          }
        ],
        bankProvince: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.province'),
            trigger: 'blur'
          }
        ],
        bankCity: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.city'),
            trigger: 'blur'
          }
        ],
        uploadedFile: [
          {
            required: true,
            validator: validateFile,
            trigger: 'change'
          }
        ]
      }
    };
  },
  watch: {
    form: {
      handler(val, oldVal) {
        this.$emit('formChange', val);
      },
      deep: true
    },
    'form.selectedCardID'(value) {
      let selectedCardInfo = this.availableCards.find(item => item.id === value);

      let isCurrentCard = selectedCardInfo && selectedCardInfo.id !== -1;
      this.form = {
        ...this.form,
        bankName: isCurrentCard ? selectedCardInfo.bankName : '',
        accountNumber: isCurrentCard ? selectedCardInfo.accountNumber : '',
        beneficiaryName: isCurrentCard ? selectedCardInfo.beneficiaryName : '',
        bankProvince: isCurrentCard ? selectedCardInfo.bankProvince : '',
        bankCity: isCurrentCard ? selectedCardInfo.bankCity : '',
        importantNotes: isCurrentCard ? selectedCardInfo.importantNotes : '',
        isRememberInfo: isCurrentCard && selectedCardInfo.id ? '' : this.form.isRememberInfo,
        userPaymentInfoId: isCurrentCard && selectedCardInfo.id ? selectedCardInfo.id : ''
      };
      this.isdisabled = isCurrentCard ? true : false;
    },
    'form.accountNumber'(value) {
      this.form.accountNumber = this.latinNumberValidator(value);
    },
    'form.beneficiaryName'(value) {
      this.form.beneficiaryName = this.latinNumberValidator(value);
    }
  },
  methods: {
    submitForm() {
      return this.$refs.withdrawFormMB.validate();
    },
    updateFileInfo(fileInfo) {
      this.fileList = fileInfo.fileList;
      this.$refs.withdrawFormMB.validateField('uploadedFile');
    },
    getFormData() {
      return {
        withdrawalType: this.withdrawalType,
        bankName: this.form.bankName,
        beneficiaryName: this.form.beneficiaryName,
        accountNumber: this.form.accountNumber,
        bankProvince: this.form.bankProvince,
        bankCity: this.form.bankCity,
        importantNotes: this.form.importantNotes,
        isRememberInfo: this.form.userPaymentInfoId ? '' : this.isRememberInfo,
        userPaymentInfoId: this.form.userPaymentInfoId ? this.form.userPaymentInfoId : '',
        targetCurrency: this.form.withdrawCurrency,
        fileList: this.fileList
      };
    }
  }
};
</script>
