import { apiPaymentSelection } from '@/resource'

export default {
  data() {
    return {
      dropdownTranslationObj: {
        1: this.$t('withdraw.default.anotherCC'),
        2: this.$t('withdraw.default.anotherBA'),
        5: this.$t('withdraw.default.anotherBA'),
        6: this.$t('withdraw.default.anotherBA'),
        8: this.$t('withdraw.default.anotherBA'),
        9: this.$t('withdraw.default.anotherBA'),
        24: this.$t('withdraw.default.anotherBA'),
        35: this.$t('withdraw.default.anotherBA'),
        39: this.$t('withdraw.default.anotherBA'),
        40: this.$t('withdraw.default.anotherBA'),
        47: this.$t('withdraw.default.anotherBA'),
        49: this.$t('withdraw.default.anotherBA'),
        63: this.$t('withdraw.default.anotherBA'),
        64: this.$t('withdraw.default.anotherBA'),
      },
      availableCards: [],
      dialog: {
        show: false,
        text: '',
        method: '',
      },
      selectedCardID: null,
      TransferFormRules: {
        selectedCardID: [
          {
            required: true,
            message: this.$t('withdraw.default.anotherBA'),
            trigger: 'blur',
          },
        ],
      },
    }
  },
  filters: {
    cardInfoDropdown: function(value, item, type, dropdownTranslationObj) {
      if (value === undefined || value === null) return ''
      if (item.cardFirstSixDigits && item.cardLastFourDigits) {
        value = item.cardFirstSixDigits + '** ****' + item.cardLastFourDigits
      } else if (item.accountNumber) {
        value = '****' + item.accountNumber.slice(-4)
      } else {
        value = dropdownTranslationObj[type]
      }
      return value
    },
  },
  mounted() {
    console.log(this.withdrawalType)
    apiPaymentSelection({ mtAcc: this.accountNumber, paymentType: this.withdrawalType })
      .then(resp => {
        this.availableCards = Array.isArray(resp.data) ? resp.data : []
      })
      .finally(resp => {
        this.addCustomOption()
      })
  },
  methods: {
    addCustomOption() {
      // Add bank account - enter manually
      this.availableCards.push({ id: -1 })
    },
    latinNumberValidator(value) {
      return value.replace(/[^a-z0-9 ]/gi, '')
    },
    validateAccountNumber(rule, value, callback) {
      if (/\s/.test(value)) {
        this.form.accountNumber = this.form.accountNumber.replace(/\s+/g, '')
        callback()
      } else if (!value || value === '') {
        callback(new Error(this.$t('withdraw.formErrorMsg.accNum')))
      } else {
        callback()
      }
    },
  },
}
