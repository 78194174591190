<template>
  <el-form
    :model="form"
    :rules="rulesDasshpe"
    ref="withdrawFormDasshpe"
    class="form_bottom_row clearfix"
    id="dasshpeForm"
  >
    <div class="fl form_row">
      <ul class="form_list">
        <li>
          <InputForm :label="$t('withdraw.vpa')" name="vpa" v-model="form.vpa"></InputForm>
        </li>
        <li>
          <InputForm :label="$t('withdraw.name')" name="upiName" v-model="form.upiName"></InputForm>
        </li>
      </ul>
      <ul class="form_list">
        <li>
          <InputForm :label="$t('withdraw.email')" name="upiEmail" type="email" v-model="form.upiEmail"></InputForm>
        </li>
        <li>
          <InputForm :label="$t('withdraw.phone')" name="upiPhoneNo" v-model="form.upiPhoneNo"></InputForm>
        </li>
      </ul>
      <ul class="form_list">
        <li>
          <InputForm :label="$t('withdraw.address')" name="upiAddress" v-model="form.upiAddress"></InputForm>
        </li>
        <li>
          <ImportantNotesInput
            :label="$t('withdraw.ImportantNotes')"
            name="importantNotes"
            v-model="form.importantNotes"
          ></ImportantNotesInput>
        </li>
      </ul>
    </div>
  </el-form>
</template>

<script>
import { validateEmail } from '@/util/validators';
import ImportantNotesInput from '@/components/form/ImportantNotesInput';

export default {
  components: { ImportantNotesInput },
  data() {
    return {
      form: {
        vpa: '',
        upiName: '',
        upiEmail: '',
        upiPhoneNo: '',
        upiAddress: '',
        importantNotes: ''
      },
      rulesDasshpe: {
        upiEmail: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.email'),
            trigger: 'blur'
          },
          {
            validator: validateEmail(this.$t('withdraw.formErrorMsg.emailFormat')),
            trigger: 'blur'
          }
        ],
        vpa: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.dynamicReq', { fieldname: this.$t('withdraw.vpa') }),
            trigger: 'blur'
          }
        ],
        upiName: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.dynamicReq', { fieldname: this.$t('withdraw.name') }),
            trigger: 'blur'
          }
        ],
        upiPhoneNo: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.dynamicReq', { fieldname: this.$t('withdraw.phone') }),
            trigger: 'blur'
          }
        ],
        upiAddress: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.dynamicReq', { fieldname: this.$t('withdraw.address') }),
            trigger: 'blur'
          }
        ]
      }
    };
  },
  watch: {
    form: {
      handler(val, oldVal) {
        this.$emit('formChange', val);
      },
      deep: true
    }
  },
  methods: {
    submitForm() {
      return this.$refs.withdrawFormDasshpe.validate();
    }
  }
};
</script>
