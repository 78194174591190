<template>
  <el-form :model="form" :rules="rulesUnionPay" ref="withdrawFormUnionPayCps" class="form_bottom_row clearfix">
    <div class="fr img_row">
      <img src="@/assets/uploads/withdraw/withdraw_unionpay_logo.png" alt />
    </div>
    <div class="fl form_row">
      <ul class="form_list">
        <li>
          <SelectForm v-model="form.bankCard" :label="$t('withdraw.SelectWithdrawCard')" name="bankCard">
            <el-option
              v-for="card in cards"
              :key="card.id"
              :value="card.id"
              :label="card.showNumber"
              :data-testid="card.id"
            ></el-option>
          </SelectForm>
        </li>
        <li>
          <InputForm
            :label="$t('withdraw.BankName')"
            :readonly="true"
            name="bankName"
            v-model="form.bankName"
          ></InputForm>
        </li>
      </ul>
      <ul class="form_list">
        <li>
          <InputForm
            :label="$t('withdraw.BankAccountNumber')"
            :readonly="true"
            name="accountNumber"
            v-model="form.accountShowNumber"
          ></InputForm>
        </li>
        <li>
          <InputForm
            :label="$t('withdraw.BankBranch')"
            :readonly="true"
            name="bankBranchName"
            v-model="form.bankBranchName"
          ></InputForm>
        </li>
      </ul>
      <ul class="form_list">
        <li>
          <InputForm
            :label="$t('withdraw.BankAccountsName')"
            :readonly="true"
            name="bankAccountName"
            v-model="form.bankAccountName"
          ></InputForm>
        </li>
        <li>
          <ImportantNotesInput
            :label="$t('withdraw.ImportantNotes')"
            maxlength="100"
            v-model="form.importantNotes"
            data-testid="notes"
          ></ImportantNotesInput>
        </li>
      </ul>
    </div>
  </el-form>
</template>

<script>
import { apiCardInformation } from '@/resource';
import ImportantNotesInput from '@/components/form/ImportantNotesInput';

export default {
  name: 'UnionPayWithdrawCps',
  props: ['dialog'],
  components: { ImportantNotesInput },
  data() {
    return {
      form: {
        bankName: '',
        accountNumber: '',
        accountShowNumber: '',
        bankBranchName: '',
        bankAccountName: '',
        importantNotes: '',
        bankCard: ''
      },
      rulesUnionPay: {
        bankCard: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.bankCard'),
            trigger: blur
          }
        ],
        bankName: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.bankName'),
            trigger: 'blur'
          }
        ],
        accountNumber: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.accNum'),
            trigger: 'blur'
          }
        ],

        bankBranchName: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.branchName'),
            trigger: 'blur'
          }
        ],
        bankAccountName: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.accName'),
            trigger: 'blur'
          }
        ]
      },
      cards: []
    };
  },
  watch: {
    form: {
      handler(val, oldVal) {
        this.$emit('formChange', val);
      },
      deep: true
    },
    'form.bankCard'(id) {
      const card = this.cards.find(item => item.id == id);
      this.form.bankName = card.bank_name;
      this.form.bankBranchName = card.branch_name;
      this.form.accountShowNumber = card.showNumber;
      this.form.accountNumber = card.card_number;
      this.form.bankAccountName = card.card_holder_name;
    }
  },
  methods: {
    submitForm() {
      return this.$refs.withdrawFormUnionPayCps.validate();
    },
    queryCards() {
      return apiCardInformation(0)
        .then(resp => {
          console.log('resp', resp);
          if (resp.data.code == 0) {
            return resp.data.data;
          } else {
            return [];
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  },
  mounted() {
    this.queryCards().then(cards => {
      if (cards == null || cards.length == 0) {
        //display a warn dialog and redirect to client portal payment details
        this.$emit('update:dialog', {
          show: true,
          text: this.$t('withdraw.noCardWarn'),
          method: 'noCard'
        });
      } else {
        this.cards = cards;
        this.cards.forEach(card => {
          card.id = card.card_number;
          card.showNumber =
            card.card_number.substr(0, 6) + '******' + card.card_number.substr(card.card_number.length - 4);
        });
        this.form.bankName = cards[0].bank_name;
        this.form.bankBranchName = cards[0].branch_name;
        this.form.accountShowNumber = cards[0].showNumber;
        this.form.bankAccountName = cards[0].card_holder_name;
        this.form.accountNumber = cards[0].card_number;
        this.form.bankCard = cards[0].card_number;
      }
    });
  }
};
</script>
