<template>
  <div class="form_bottom_row clearfix">
    <el-form :model="form" :rules="rulesInternational" ref="withdrawFormInternational" class="form_row">
      <ul class="form_list">
        <li>
          <InputForm
            :label="$t('withdraw.BankName')"
            name="bankName"
            maxlength="100"
            v-model="form.bankName"
            :disabled="isdisabled"
          ></InputForm>
        </li>
        <li>
          <InputForm
            :label="$t('withdraw.BankAddress')"
            name="bankAddress"
            maxlength="100"
            v-model="form.bankAddress"
            :disabled="isdisabled"
          ></InputForm>
        </li>
      </ul>
      <ul class="form_list">
        <li>
          <InputForm
            :label="$t('withdraw.AccountNumberIBAN')"
            name="accountNumber"
            maxlength="100"
            v-model.trim="form.accountNumber"
            :disabled="isdisabled"
          ></InputForm>
        </li>
        <li>
          <InputForm
            :label="$t('withdraw.beneficiaryName')"
            name="beneficiaryName"
            maxlength="100"
            v-model="form.beneficiaryName"
            :disabled="isdisabled"
          ></InputForm>
        </li>
      </ul>
      <ul class="form_list">
        <li>
          <InputForm
            :label="$t('withdraw.AccountHoldersAddress')"
            name="holderAddress"
            maxlength="100"
            v-model="form.holderAddress"
            :disabled="isdisabled"
          ></InputForm>
        </li>
        <li>
          <InputForm
            :label="$t('withdraw.Swift')"
            name="swift"
            maxlength="100"
            v-model="form.swift"
            :disabled="isdisabled"
          ></InputForm>
        </li>
      </ul>
      <ul class="form_list" v-if="userCountryCode == canCountryCode">
        <li>
          <InputForm label="Transit Number" name="transitNumber" v-model="canadaOnly.transitNumber"></InputForm>
        </li>
        <li>
          <InputForm
            label="Institution Number"
            name="institutionNumber"
            v-model="canadaOnly.institutionNumber"
          ></InputForm>
        </li>
      </ul>
      <ul class="form_list">
        <li>
          <InputForm
            :label="$t('withdraw.ABA_SortCodeABA')"
            maxlength="100"
            v-model="form.sortCode"
            name="sortCode"
          ></InputForm>
        </li>
        <li>
          <ImportantNotesInput
            :label="$t('withdraw.ImportantNotes')"
            maxlength="100"
            v-model="form.importantNotes"
            name="notes"
          ></ImportantNotesInput>
        </li>
      </ul>
      <div class="checkbox_wrapper" v-if="!form.userPaymentInfoId">
        <el-checkbox v-model="form.isRememberInfo" data-testid="remember">
          {{ $t('withdraw.default.remember') }}</el-checkbox
        >
      </div>
    </el-form>
  </div>
</template>

<script>
import bankTransferMixin from '@/mixins/withdrawl/bankTransfer';
import withdrawlMixin from '@/mixins/withdrawl/withdrawl';
import ImportantNotesInput from '@/components/form/ImportantNotesInput';

export default {
  props: [
    'accountNumber',
    'amount',
    'withdrawalType',
    'userCountryCode',
    'selectedCardInfo',
    'transferCountryString',
    'chooseWithdrawalType'
  ],
  components: { ImportantNotesInput },
  mixins: [bankTransferMixin, withdrawlMixin],
  data() {
    return {
      form: {
        country: 2,
        bankName: '',
        bankAddress: '',
        accountNumber: '',
        beneficiaryName: '',
        holderAddress: '',
        swift: '',
        sortCode: '',
        importantNotes: '',
        userPaymentInfoId: null
      },
      rulesInternational: {
        bankName: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.bankName'),
            trigger: 'blur'
          }
        ],
        bankAddress: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.bankAddress'),
            trigger: 'blur'
          }
        ],
        accountNumber: [
          {
            required: true,
            validator: this.validateAccountNumber,
            trigger: 'blur'
          }
        ],
        beneficiaryName: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.beneficiaryNameReq'),
            trigger: 'blur'
          }
        ],
        holderAddress: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.holderAddress'),
            trigger: 'blur'
          }
        ],
        swift: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.swift'),
            trigger: 'blur'
          }
        ]
      },
      canadaOnly: {
        transitNumber: '',
        institutionNumber: ''
      },
      canCountryCode: 4575 //Canada
    };
  },
  watch: {
    form: {
      handler(val, oldVal) {
        this.$emit('formChange', val);
      },
      deep: true,
      immediate: true
    },
    selectedCardInfo: {
      immediate: true,
      handler(value) {
        console.log(value);
        if (value && value.id !== -1) {
          var notesObj = {};
          if (value.importantNotes && this.userCountryCode == this.canCountryCode) {
            var properties = value.importantNotes.split(', ');
            properties.forEach(function(property) {
              var tup = property.split(':');
              notesObj[tup[0]] = tup[1];
            });
            console.log(notesObj);
            this.canadaOnly = {
              transitNumber: this.selectedCardInfo.importantNotes ? notesObj['Transit Number'] : '',
              institutionNumber: this.selectedCardInfo.importantNotes ? notesObj['Institution Number'] : ''
            };
          }
          this.form = {
            country: this.form.country,
            bankName: this.selectedCardInfo.bankName,
            bankAddress: this.selectedCardInfo.bankAddress,
            accountNumber: this.selectedCardInfo.accountNumber,
            beneficiaryName: this.selectedCardInfo.beneficiaryName,
            holderAddress: this.selectedCardInfo.holderAddress,
            swift: this.selectedCardInfo.swift,
            sortCode: this.selectedCardInfo.sortCode,
            importantNotes:
              this.selectedCardInfo.importantNotes && this.userCountryCode == this.canCountryCode
                ? notesObj['notes']
                : this.selectedCardInfo.importantNotes && this.userCountryCode != this.canCountryCode
                ? this.selectedCardInfo.importantNotes
                : '',
            isRememberInfo: this.selectedCardInfo.id ? '' : this.form.isRememberInfo,
            userPaymentInfoId: this.selectedCardInfo.id ? this.selectedCardInfo.id : ''
          };
          this.isdisabled = true;
        } else {
          this.isdisabled = false;
          this.form.userPaymentInfoId = '';
        }
      }
    }
  },
  methods: {
    submitForm() {
      if (this.userCountryCode == this.canCountryCode) {
        this.form.importantNotes = `Transit Number: ${this.canadaOnly.transitNumber}, Institution Number: ${this.canadaOnly.institutionNumber}, notes: ${this.form.importantNotes}`;
      }
      return this.$refs.withdrawFormInternational.validate();
    }
  }
};
</script>
