<template>
  <div>
    <el-form :model="form" :rules="rules" ref="withdrawFormChild" class="form_bottom_row clearfix">
      <div class="fr img_row">
        <img class="small" src="@/assets/uploads/withdraw/bitcoin.png" alt />
      </div>
      <div class="fl form_row">
        <ul class="form_list">
          <li>
            <InputForm
              :label="$t('withdraw.BitcoinEmail')"
              name="cryptoWalletAddress"
              maxlength="100"
              type="text"
              v-model="form.cryptoWalletAddress"
            ></InputForm>
          </li>
          <!-- crypto的出金增加验证码 -->
          <li>
            <VerificationCode ref="verificationCode" namespace="IB_WITHDRAW"></VerificationCode>
          </li>
          <li>
            <ImportantNotesInput
              :label="$t('withdraw.ImportantNotes')"
              name="notes"
              maxlength="100"
              type="text"
              v-model="form.importantNotes"
            ></ImportantNotesInput>
          </li>
        </ul>
        <div class="alert_info">
          {{ $t('withdraw.cryptoAlert') }}
        </div>
      </div>
    </el-form>
    <disclaimer-dialog></disclaimer-dialog>
  </div>
</template>

<script>
import disclaimerDialog from '@/components/disclaimerDialog';
import VerificationCode from '@/components/rebateWithdraw/VerificationCode';
import ImportantNotesInput from '@/components/form/ImportantNotesInput';

export default {
  components: { disclaimerDialog, VerificationCode, ImportantNotesInput },
  data() {
    const validateAddress = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('withdraw.formErrorMsg.bitcoin')));
      } else if (!/^(bc|[13])/.test(value)) {
        callback(new Error(this.$t('withdraw.formErrorMsg.bitcoinFormat')));
      } else if (value.length < 26 || value.length > 42) {
        callback(new Error(this.$t('withdraw.formErrorMsg.bitcoinAddressLength')));
      } else callback();
    };
    return {
      form: {
        cryptoWalletAddress: '',
        importantNotes: ''
      },
      rules: {
        cryptoWalletAddress: [
          {
            required: true,
            validator: validateAddress,
            trigger: 'blur'
          }
        ]
      }
    };
  },
  watch: {
    form: {
      handler(val, oldVal) {
        this.$emit('formChange', val);
      },
      deep: true
    }
  },
  methods: {
    submitForm() {
      return this.$refs.withdrawFormChild.validate() && this.$refs.verificationCode.submitForm();
    },
    getVerificationCode() {
      return this.$refs.verificationCode.getFormDataOfChild();
    }
  }
};
</script>
