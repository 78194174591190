<template>
  <el-form :model="form" :rules="TransferFormRules" ref="withdrawFormHongkong" class="form_bottom_row clearfix" id="hongkongForm">
    <div class="fr img_row" v-if="form.selectedCardID || form.selectedCardID === 0">
      <img class="small" src="@/assets/uploads/withdraw/bank.png" alt />
    </div>
    <div class="fl form_row">
      <ul class="form_list clearfix">
        <li class="fl">
          <SelectForm
            v-model="form.selectedCardID"
            :label="$t('withdraw.default.selectBA')"
            data-testid="numberSelection"
            name="selectedCardID"
          >
            <el-option
              v-for="item in availableCards"
              :key="item.id"
              :value="item.id"
              :label="item.id | cardInfoDropdown(item, withdrawalType, dropdownTranslationObj)"
              :data-testid="`select_${item.id}`"
            ></el-option>
          </SelectForm>
        </li>
      </ul>
    </div>
    <div class="fl form_row" v-if="form.selectedCardID || form.selectedCardID === 0">
      <ul class="form_list">
        <li>
          <SelectForm v-model="form.bankName" name="bankName" :label="$t('withdraw.bankCode')" :disabled="isdisabled" @change="handleBankCodeChange">
            <el-option
              v-for="bank in banks"
              :key="bank.bankCode"
              :value="bank.value"
            ></el-option>
          </SelectForm>
        </li>
        <li>
          <SelectForm
            :label="$t('withdraw.bankBranchCode')"
            name="bankBranchName"
            v-model="form.bankBranchName"
            :disabled="isdisabled"
          >  
          <el-option
             v-for="bankBranch in bankBranches"
            :key="bankBranch.branchCode"
            :value="bankBranch.value"
            ></el-option>
            </SelectForm>
        </li>
      </ul>
      <ul class="form_list">
        <li>
          <InputForm
            :label="$t('withdraw.BankAccountsName')"
            name="bankAccName"
            v-model="form.bankAccName"
            :disabled="isdisabled"
          ></InputForm>
        </li>
        <li>
          <InputForm
            :label="$t('withdraw.BankAccountNumber')"
            name="accountNumber"
            v-model.trim="form.accountNumber"
            :disabled="isdisabled"
          ></InputForm>
        </li>
      </ul>
      <ul class="form_list">
        <li>
          <SelectForm
            v-model="form.withdrawCurrency"
            :label="$t('withdraw.bankAccountCurrency')"
            name="withdrawCurrency"
          >
            <el-option
              v-for="currency in supportedCurrencies"
              :key="currency"
              :value="currency"
              :label="currency"
              :data-testid="currency"
            ></el-option>
          </SelectForm>
          <div class="rate" v-if="isHKD">
            {{ $t('rate.rate', { oldCurrency: 'USD', newCurrency: 'HKD' }) }}
            <span>{{ rate }}</span>
          </div>
        </li>
        <li>
          <ImportantNotesInput :label="$t('withdraw.ImportantNotes')" maxlength="100" v-model="form.importantNotes"></ImportantNotesInput>
        </li>
      </ul>
      <ul class="form_list" v-if="!isdisabled">
        <li>
          <el-form-item class="upload" prop="uploadedFile">
            <div class="labelTitle">
              <span class="required_icon">*</span>
              <label for="">{{ $t('withdraw.transfer.label.upload') }}</label>
            </div>
            <vUpload :limit="1" v-on:updateFileInfo="updateFileInfo" data-testid="updateFile"></vUpload>
          </el-form-item>
        </li>
        <li>
            <p class="int_p" v-html="$t('withdraw.transfer.info', { platform: $config.info.fullName })"></p>
        </li>
      </ul>
      <div class="checkbox_wrapper" v-if="!form.userPaymentInfoId">
        <el-checkbox v-model="isRememberInfo" data-testid="rememberInfo">
          {{ $t('withdraw.default.remember') }}</el-checkbox
        >
      </div>
    </div>
  </el-form>
</template>

<script>
import { apiWithdrawalQueryRateUSDByTargetCurrency,apiGetWithdrawalBankCode,apiGetWithdrawalBankBranchCode } from '@/resource';
import withdrawlMixin from '@/mixins/withdrawl/withdrawl';
import bankTransferMixin from '@/mixins/withdrawl/bankTransfer';
import vUpload from '@/components/vUpload';
import ImportantNotesInput from '@/components/form/ImportantNotesInput';

export default {
  props: {
    withdrawalType: Number,
    bankList: Object
  },
  mixins: [bankTransferMixin, withdrawlMixin],
  components: { vUpload, ImportantNotesInput },
  data() {
     const validateFile = (rule, value, callback) => {
      if (this.fileList.length < 1) callback(new Error(this.$t('withdraw.transfer.formValidation.upload')));
      else callback();
    };
    return {
      form: {
        bankName:'',
        bankBranchName:'',
        bankAccName: '',
        accountNumber: '',
        importantNotes: '',
        userPaymentInfoId: '',
        selectedCardID: null
      },
      isRememberInfo:true,
      banks:[],
      bankBranches:[],
      isHKD: false,
      supportedCurrencies: ['HKD', 'USD'],
      rate: 7.75,
      isRemember: true,
      fileList: [],
      TransferFormRules: {
        bankName: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.bankCodeReq'),
            trigger: ['blur','change']
          }
        ],
        bankBranchName:[
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.bankBranchCodeReq'),
            trigger:['blur','change']
          }
        ],
        bankAccName: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.bankName'),
            trigger: ['blur','change']
          }
        ],
        accountNumber: [
          {
            required: true,
            validator: this.validateAccountNumber,
            trigger: ['blur','change']
          }
        ],
        withdrawCurrency: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.dynamicReq', {
              fieldname: this.$t('withdraw.bankAccountCurrency')
            }),
            trigger: 'change'
          }
        ],
         uploadedFile: [
          {
            required: true,
            validator: validateFile,
            trigger: 'change'
          }
        ]
      }
    };
  },
  watch: {
    'form.bankCode'(value) {
      if(!isNaN(value)){
        this.queryBankBranchCode(value);
        this.resetBankBranchCode();
        this.setBankName(value);
      }
    },
    'form.bankBranchCode'(value) {
       if(!isNaN(value)){
          this.setBankBranchName(value)
       }
    },
    'form.selectedCardID'(value) {
      let selectedCardInfo = this.availableCards.find(item => item.id === value);
      let isCurrentCard = selectedCardInfo && selectedCardInfo.id !== -1;
      this.form = {
        ...this.form,
        bankAccName:isCurrentCard ? selectedCardInfo.bankAccountName : '',
        bankName: isCurrentCard ? selectedCardInfo.bankName : '',
        bankBranchName: isCurrentCard ? selectedCardInfo.bankBranchName : '',
        accountNumber: isCurrentCard ? selectedCardInfo.accountNumber : '',
        importantNotes: isCurrentCard ? selectedCardInfo.importantNotes : '',
        userPaymentInfoId: isCurrentCard && selectedCardInfo.id ? selectedCardInfo.id : ''
      };
      this.isdisabled = isCurrentCard ? true : false;
    },
    'form.accountNumber'(value) {
      this.form.accountNumber = this.latinNumberValidator(value);
    },
    'form.withdrawCurrency'(value) {
      if (value === 'HKD') this.isHKD = true;
      else this.isHKD = false;
    },
  },
  methods: {
     getFormData() {
      return {
        withdrawalType: this.withdrawalType,
        bankBranchName:this.form.bankBranchName,
        bankName:this.form.bankName,
        bankAccountName: this.form.bankAccName,
        accountNumber: this.form.accountNumber,
        importantNotes: this.form.importantNotes,
        isRememberInfo: this.form.userPaymentInfoId ? '' : this.isRememberInfo,
        userPaymentInfoId: this.form.userPaymentInfoId ? this.form.userPaymentInfoId : '',
        fileList: this.fileList,
        targetCurrency: this.form.withdrawCurrency
      };
    },
    updateFileInfo(fileInfo) {
      this.fileList = fileInfo.fileList;
      this.$refs.withdrawFormHongkong.validateField('uploadedFile');
    },
    submitForm() {
      return this.$refs.withdrawFormHongkong.validate();
    },
    queryRate() {
      apiWithdrawalQueryRateUSDByTargetCurrency('HKD')
        .then(resp => {
          if (resp.data.code == 0) this.rate = resp.data.data;
        })
        .catch(err => {
          this.errorMessage(
            this.$t('deposit.default.rate.result.rateError', { oldCurrency: 'USD', newCurrency: 'HKD' })
          );
        });
    },
    queryBankCode() {
      apiGetWithdrawalBankCode ()
        .then(resp => {
          if (resp.data.code == 0) this.banks = resp.data.data.map(bank=>({...bank,value:bank.bankCode + ' - ' + bank.bankName}));
        })
        .catch(err => {
          this.errorMessage(
            this.$t('Get Bank Code Error.')
          );
        });
    },
     queryBankBranchCode(bankCode) {
      apiGetWithdrawalBankBranchCode (bankCode)
        .then(resp => {
          if (resp.data.code == 0) this.bankBranches = resp.data.data.map(bank=>({...bank,value:bank.branchCode + ' - ' + bank.branchName}));
        })
        .catch(err => {
          this.errorMessage(
            this.$t('Get Bank Branch Code Error.')
          );
        });
    },
    handleBankCodeChange(value){
      let currentBankCode = this.banks.find(bank=>bank.value === value).bankCode;
      this.queryBankBranchCode(currentBankCode); 
      this.form.bankBranchName = '';
    }
  },
  mounted() {
    this.queryRate();
    this.queryBankCode();
  }
};
</script>
