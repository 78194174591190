<template>
  <el-form :model="form" :rules="TransferFormRules" ref="withdrawFormIndia" class="form_bottom_row clearfix">
    <div class="fl form_row">
      <ul class="form_list clearfix">
        <li class="fl">
          <SelectForm v-model="form.selectedCardID" :label="$t('withdraw.default.selectBA')" name="selectedCardID">
            <el-option
              v-for="item in availableCards"
              :key="item.id"
              :value="item.id"
              :label="item.id | cardInfoDropdown(item, withdrawalType, dropdownTranslationObj)"
              :data-testid="`select_${item.id}`"
            ></el-option>
          </SelectForm>
        </li>
      </ul>
    </div>
    <div class="fl form_row" v-if="form.selectedCardID || form.selectedCardID === 0">
      <ul class="form_list">
        <li>
          <InputForm
            :label="$t('withdraw.BankName')"
            name="bankName"
            maxlength="100"
            v-model="form.bankName"
            :disabled="isdisabled"
          ></InputForm>
        </li>
        <li>
          <InputForm
            :label="$t('withdraw.BankAddress')"
            name="bankAddress"
            maxlength="100"
            v-model="form.bankAddress"
            :disabled="isdisabled"
          ></InputForm>
        </li>
      </ul>
      <ul class="form_list">
        <li>
          <InputForm
            :label="$t('withdraw.AccountNumberIBAN')"
            name="accountNumber"
            maxlength="100"
            v-model.trim="form.accountNumber"
            :disabled="isdisabled"
          ></InputForm>
        </li>
        <li>
          <InputForm
            :label="$t('withdraw.BankBeneficiaryName')"
            name="beneficiaryName"
            maxlength="100"
            v-model="form.beneficiaryName"
            :disabled="isdisabled"
          ></InputForm>
        </li>
      </ul>
      <ul class="form_list">
        <li>
          <InputForm
            :label="$t('withdraw.ifscCode')"
            name="ifscCode"
            maxlength="100"
            v-model="form.ifscCode"
            :disabled="isdisabled"
          ></InputForm>
        </li>
        <li>
          <InputForm
            :label="$t('withdraw.BankBranch')"
            name="bankBranchName"
            maxlength="100"
            v-model="form.bankBranchName"
            :disabled="isdisabled"
          ></InputForm>
        </li>
      </ul>
      <ul class="form_list">
        <li>
          <InputForm
            :label="$t('withdraw.email')"
            name="email"
            maxlength="100"
            v-model="form.email"
            :disabled="isdisabled"
          ></InputForm>
        </li>
        <li>
          <InputForm
            :label="$t('withdraw.phone')"
            name="phoneNumber"
            maxlength="100"
            v-model="form.phoneNumber"
            :disabled="isdisabled"
          ></InputForm>
        </li>
      </ul>
      <ul class="form_list">
        <li>
          <ImportantNotesInput
            :label="$t('withdraw.ImportantNotes')"
            maxlength="100"
            v-model="form.importantNotes"
            name="notes"
          ></ImportantNotesInput>
        </li>
      </ul>
      <div class="checkbox_wrapper" v-if="!form.userPaymentInfoId">
        <el-checkbox v-model="form.isRememberInfo" data-testid="rememberInfo">
          {{ $t('withdraw.default.remember') }}</el-checkbox
        >
      </div>
    </div>
  </el-form>
</template>

<script>
import withdrawlMixin from '@/mixins/withdrawl/withdrawl';
import bankTransferMixin from '@/mixins/withdrawl/bankTransfer';
import { validateEmail } from '@/util/validators';
import ImportantNotesInput from '@/components/form/ImportantNotesInput';

export default {
  props: {
    withdrawalType: Number,
    bankList: Object
  },
  components: { ImportantNotesInput },
  mixins: [bankTransferMixin, withdrawlMixin],
  data() {
    return {
      form: {
        bankName: '',
        bankAddress: '',
        accountNumber: '',
        beneficiaryName: '',
        ifscCode: '',
        bankBranchName: '',
        email: '',
        phoneNumber: '',
        importantNotes: '',
        userPaymentInfoId: '',
        selectedCardID: null
      },
      isRemember: true,
      TransferFormRules: {
        bankName: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.bankName'),
            trigger: 'blur'
          }
        ],
        bankAddress: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.bankAddress'),
            trigger: 'blur'
          }
        ],
        accountNumber: [
          {
            required: true,
            validator: this.validateAccountNumber,
            trigger: 'blur'
          }
        ],
        beneficiaryName: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.beneName'),
            trigger: 'blur'
          }
        ],
        email: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.dynamicReq', {
              fieldname: this.$t('withdraw.email')
            }),
            trigger: 'blur'
          },
          {
            validator: validateEmail(this.$t('withdraw.formErrorMsg.emailFormat')),
            trigger: 'blur'
          }
        ],
        bankBranchName: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.dynamicReq', { fieldname: this.$t('withdraw.bankBranch') }),
            trigger: 'blur'
          }
        ],
        ifscCode: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.dynamicReq', { fieldname: this.$t('withdraw.ifscCode') }),
            trigger: 'blur'
          }
        ],
        phoneNumber: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.dynamicReq', { fieldname: this.$t('withdraw.phone') }),
            trigger: 'blur'
          }
        ]
      }
    };
  },
  watch: {
    form: {
      handler(val, oldVal) {
        this.$emit('formChange', val);
      },
      deep: true
    },
    'form.selectedCardID'(value) {
      let selectedCardInfo = this.availableCards.find(item => item.id === value);
      console.log(selectedCardInfo);
      let isCurrentCard = selectedCardInfo && selectedCardInfo.id !== -1;
      this.form = {
        ...this.form,
        bankName: isCurrentCard ? selectedCardInfo.bankName : '',
        bankAddress: isCurrentCard ? selectedCardInfo.bankAddress : '',
        accountNumber: isCurrentCard ? selectedCardInfo.accountNumber : '',
        beneficiaryName: isCurrentCard ? selectedCardInfo.beneficiaryName : '',
        bankBranchName: isCurrentCard ? selectedCardInfo.bankBranchName : '',
        ifscCode: isCurrentCard ? selectedCardInfo.ifscCode : '',
        email: isCurrentCard ? selectedCardInfo.email : '',
        phoneNumber: isCurrentCard ? selectedCardInfo.phoneNumber : '',
        importantNotes: isCurrentCard ? selectedCardInfo.importantNotes : '',
        isRememberInfo: isCurrentCard && selectedCardInfo.id ? '' : this.form.isRememberInfo,
        userPaymentInfoId: isCurrentCard && selectedCardInfo.id ? selectedCardInfo.id : ''
      };
      this.isdisabled = isCurrentCard ? true : false;
    },
    'form.accountNumber'(value) {
      this.form.accountNumber = this.latinNumberValidator(value);
    },
    'form.beneficiaryName'(value) {
      this.form.beneficiaryName = this.latinNumberValidator(value);
    }
  },
  methods: {
    submitForm() {
      return this.$refs.withdrawFormIndia.validate();
    }
  }
};
</script>
