<template>
  <div id="transferCountry">
    <el-form class="form_bottom_row clearfix">
      <div class="fr img_row" v-if="selectedCardID || selectedCardID === 0">
        <img src="@/assets/uploads/withdraw/withdraw_bank_logo.png" alt />
      </div>
      <div class="fl form_row">
        <ul class="form_list">
          <li>
            <SelectForm v-model="selectedCardID" :label="$t('withdraw.default.selectBA')" name="selectedCard">
              <el-option
                v-for="item in availableCards"
                :key="item.id"
                :value="item.id"
                :label="item.id | cardInfoDropdown(item, 2, dropdownTranslationObj)"
                :data-testid="`select_${item.id}`"
              ></el-option>
            </SelectForm>
          </li>
          <li v-if="selectedCardID || selectedCardID === 0">
            <WithdrawlSharedOptions
              v-model="transferCountryString"
              :label="chooseWithdrawalType.subTypeNameKey"
              :list="chooseWithdrawalType.withdrawSubTypes"
              :disabled="selectedCardID !== -1"
            ></WithdrawlSharedOptions>
          </li>
        </ul>
      </div>
    </el-form>
    <component
      :is="currentComponent"
      ref="bankChild"
      :selectedCardInfo="selectedCardInfo"
      :transferCountryString="transferCountryString"
      :chooseWithdrawalType="chooseWithdrawalType"
      :withdrawalType="withdrawalType"
      :userCountryCode="userCountryCode"
      @formChange="formChange"
    ></component>
  </div>
</template>
<script>
import International from '@/components/rebateWithdraw/International';
import WithdrawlSharedOptions from '@/components/form/WithdrawlSharedOptions';
import withdrawlMixin from '@/mixins/withdrawl/withdrawl';
import bankTransferMixin from '@/mixins/withdrawl/bankTransfer';

export default {
  name: 'BankTransfer',
  components: {
    WithdrawlSharedOptions,
    International
  },
  props: ['withdrawalType', 'chooseWithdrawalType', 'userCountryCode'],
  mixins: [bankTransferMixin, withdrawlMixin],
  data() {
    return {
      currentComponent: null,
      transferCountry: null,
      selectedCardInfo: null,
      transferCountryString: null,
      form: {}
    };
  },
  watch: {
    selectedCardID(value) {
      this.selectedCardInfo = this.availableCards.filter(item => item.id === value)[0];
      console.log(this.selectedCardInfo);
      this.transferCountry = this.selectedCardInfo.country;
      this.transferCountryString = this.transferCountry ? this.transferCountry.toString() : null;
      console.log(this.transferCountryString);
    },
    transferCountryString(value) {
      this.$emit('changeBankComponent', value);
      switch (value) {
        case '2':
          this.currentComponent = International;
          break;
        default:
          this.currentComponent = null;
          break;
      }
    },
    form: {
      handler(val, oldVal) {
        this.$emit('formChange', val);
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    submitForm() {
      return this.$refs.bankChild.submitForm();
    },
    validateFormPropsHandler() {
      this.$emit('validateForm');
    },
    formChange(form) {
      this.form = form;
    }
  }
};
</script>
